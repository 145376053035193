<template>
  <v-form class="pa-6">
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="9"
        class="pr-1"
      >
        <v-radio-group
          v-model="formData.extendSubscription"
          class="mt-0 text-sm-center border-thin mb-1 pa-4"
          hide-details
          @change="getSummaryPrice"
        >
          <div class="d-flex justify-space-around pa-4 flex-column flex-sm-row">
            <div class="subscription flex-row flex-sm-column mb-2 mb-sm-0">
              <p class="order-last order-sm-first mb-0 mb-sm-2">
                {{ $t('productDetails.keepUpdateSubscription') }}
              </p>
              <v-radio
                class="justify-center"
                data-cy="keep-update-radio"
                :value="false"
              />
            </div>
            <div class="subscription flex-row flex-sm-column">
              <p class="d-none d-sm-block mb-sm-auto">
                {{ $t('productDetails.extendUpdateSubscription') }}
              </p>
              <div class="d-flex align-center justify-center">
                <v-radio
                  data-cy="extend-update-radio"
                  class="mb-0"
                  :value="true"
                />
                <div>
                  <p class="d-block d-sm-none mb-0">
                    {{ $t('productDetails.extendUpdateSubscription') }}
                  </p>
                  <div class="d-flex align-center">
                    <v-select
                      v-model="formData.subscriptionExtensionInYears"
                      data-cy="extend-update-subscription"
                      dense
                      hide-details
                      :items="availableSubscribtionExtends"
                      @input="onExtendingSubscriptionChange(true)"
                      @change="getSummaryPrice()"
                    />
                    <span
                      class="ml-2"
                      data-cy="extend-update-year"
                    >{{ extendedExpiration }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-radio-group>

        <div class="border-thin">
          <material-product-details-change-plan-max-users-count-slider
            :initial-value="product.maxUsersCount"
            :type="TYPE_SERVER"
            @change="updateUsersCount"
          />
        </div>
      </v-col>

      <v-col
        cols="12"
        sm="3"
        class="text-center pt-1 pt-sm-0"
      >
        <material-product-details-change-plan-request-quotation-card />
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col
        cols="12"
        class="pa-0"
      >
        <material-product-details-change-plan-bundles-card
          v-if="product.bundlesOnly || product.pricingType === PRICING_TYPE_MIX"
          :bundle-id="formData.bundleId"
          @bundle-change="onBundleChange"
        />
        <material-product-details-change-plan-plugins-card
          v-if="!product.bundlesOnly || product.pricingType === PRICING_TYPE_MIX"
          :selected-plugins="formData.plugins"
          :show-additional-plugins-only="product.pricingType === PRICING_TYPE_MIX"
          @plugin-change="onPluginChange"
          @bundle-change="onPluginBundleChange"
        />

        <div class="mx-n6 my-6">
          <v-divider />
        </div>

        <material-product-details-change-plan-order-summary :form-data="formData" />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import moment from 'moment'
import { isEqual } from 'lodash'
import { mapState, mapActions } from 'vuex'
import { MAX_YEARS_OF_SUBSCRIPTION_EXTENSION, PRICING_TYPE_MIX, TYPE_SERVER } from '@/constants/app'

export default {
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data () {
    const availableSubscribtionExtends = [...Array(MAX_YEARS_OF_SUBSCRIPTION_EXTENSION).keys()].map(y => ({
      value: y + 1,
      text: this.$i18n.tc('productDetails.years', y + 1)
    }))

    return {
      formData: this.getDefaultFormData(availableSubscribtionExtends),
      availableSubscribtionExtends,
      PRICING_TYPE_MIX,
      TYPE_SERVER
    }
  },
  computed: {
    ...mapState('productDetails', ['availablePlugins']),
    extendedExpiration () {
      const oldDate = moment(this.formData.expiration)
      const newDate = oldDate.add(this.formData.subscriptionExtensionInYears, 'years')
      return newDate.format('DD.MM.YYYY')
    }
  },
  watch: {
    formData: {
      handler: function (val, oldVal) {
        if (!isEqual(val, oldVal)) {
          this.getSummaryPrice()
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('productDetails', ['getOrderPrice']),
    getSummaryPrice () {
      this.$emit('form-summary', this.formData)
      this.getOrderPrice(this.formData)
    },
    onExtendingSubscriptionChange (extendSubscription) {
      this.formData = { ...this.formData, extendSubscription }
    },
    getDefaultFormData (availableSubscribtionExtends = this.availableSubscribtionExtends) {
      return {
        ...this.product,
        clientId: this.$route.params.clientId,
        subscriptionExtensionInYears:
          this.product.subscriptionExtensionInYears || availableSubscribtionExtends[0].value
      }
    },
    updateUsersCount (maxUsersCount) {
      this.formData = { ...this.formData, maxUsersCount }
    },
    onPluginChange ({ plugin, checked }) {
      this.formData = {
        ...this.formData,
        plugins: checked
          ? [...this.formData.plugins, plugin]
          : this.formData.plugins.filter(plug => plug.easy_price_book_product_id !== plugin.easy_price_book_product_id)
      }
    },
    onPluginBundleChange ({ bundle: { plugins }, checked }) {
      const pluginIds = plugins.map(p => p.easy_price_book_product_id)

      this.formData = {
        ...this.formData,
        plugins: checked
          ? [...this.formData.plugins, ...plugins]
          : this.formData.plugins.filter(plug => !pluginIds.includes(plug.easy_price_book_product_id))
      }
    },
    onBundleChange (bundleId) {
      this.formData = { ...this.formData, bundleId }
    }
  }
}
</script>

<style scoped>
.extend-subscription-select {
  display: inline-block;
  max-width: 50%;
}

.subscription {
  display: flex;
  flex: 1 1 50%;
}
</style>
